import './src-concat/01_modernizr.min.js'
import './src-concat/03_lazysizes.min.js'
import './src-concat/04_magnific-popup.min'
import './src-concat/05_cssua.min.js'
import './src-concat/06_slick.min.js'
import './src-concat/07_jquery.jscroll.min.js'
import Cookies from 'js-cookie'
import { TimelineMax } from "gsap"

(function($, window, document, undefined) {
    'use strict';

    /**
     * This is a description for Mangrove object.
     *
     * @namespace Mangrove
     * @author Mangrove
     */

    var Mangrove = {

        /**
         * Initialize object
         * @function init
         * @memberof Mangrove
         */

        init: function() {

            this._cache();
            this._events();
            this._initPopups();
            this._initSliders();
            this._initInfiniteScroll();
            this._showGdpr();
            this._closeBanner();

            if (this.$heroAnimation.length) {
                this._initAnimiation();
            }

            if (this.$scrollModal.length) {
                this._initScrollModals();
            }

            if (this.$body.hasClass('page-template-page-neighborhood-kit')) {
                this._detectFilters();
                this._loadAllPrototypes();
            }

            if (this.$body.hasClass('page-template-page-buzz')) {
                this._loadAllVideos();
                this._loadAllPublications();
                this._loadAllPress();
                this._videoMarquee();
            }


        }, // init()

        /**
         * Cache reusable data
         * @function cache
         * @memberof Mangrove
         */

        _cache: function() {

            this.$win = $(window);
            this.$doc = $(document);
            this.$html = $('html');
            this.$body = $('body');
            this.$bodyHtml = $('body, html');
            this.$mainWrap = $('main');
            this.$header = $('.js-header');
            this.$mobileNav = $('.js-mobile-nav');
            this.$hamburger = $('.js-hamburger');
            this.$posts = $('.js-posts');
            this.$videos = $('.js-videos');
            this.$publications = $('.js-publications');
            this.$press = $('.js-press');
            this.$gridSector = $('.js-grid-sector');
            this.$btnSector = $('.js-filter-sector');
            this.$gridPrototypes = $('.js-prototypes-all');
            this.$emptyInfo = $('.js-empty-info');
            this.$pressEmptyInfo = $('.js-press-empty-info');
            this.$downloadFormId = 3;
            this.$scrollFired = false;
            this.$scrollModal = $('.js-popup-scroll');
            this.$parallaxScene = $('#scene');
            this.$heroAnimation = $('.js-hero-animation');
            this.$allPrototypes = false;
            this.$tempPrototypes = false;
            this.$currentPrototypePage = 1;
            this.$maxPrototypePage = 1;
            this.$prototypesPerPage = 6;
            this.$allPublications = false;
            this.$tempPublications = false;
            this.$currentPublicationsPage = 1;
            this.$maxPublicationsPage = 1;
            this.$publicationsPerPage = 9;
            this.$allVideos = false;
            this.$tempVideos = false;
            this.$currentVideoPage = 1;
            this.$maxVideoPage = 1;
            this.$videosPerPage = 9;
            this.$allPress = false;
            this.$tempPress = false;
            this.$currentPressPage = 1;
            this.$maxPressPage = 1;
            this.$pressPerPage = 9;

        }, // _cache()

        /**
         * Attach event listeners
         * @function _events
         * @memberof Mangrove
         */

        _events: function() {

            this.$html.on(
                'click',
                '.js-hamburger',
                this._showMobileNav.bind(this)
            );

            this.$html.on(
                'click',
                '.js-filter-sector',
                this._filterSector.bind(this)
            );

            this.$html.on(
                'click',
                '.js-hero-cta',
                this._scrollHero.bind(this)
            );

            this.$html.on(
                'click',
                '.js-scroll-to',
                this._scrollToSection.bind(this)
            );

            this.$doc.on(
                'click',
                '.js-prototype-filter',
                this._filterPrototypes.bind(this)
            );

            this.$doc.on(
                'change',
                '.js-prototype-filter-dropdown',
                this._filterPrototypes.bind(this)
            );

            this.$html.on(
                'click',
                '.js-cookie-accept',
                this._cookieAccept.bind(this)
            );

            this.$doc.on(
                'click',
                '.js-search',
                event => this._showSearch(event)
            );

            this.$doc.bind('gform_confirmation_loaded', function(event, formId) {

                if (formId == Mangrove.$downloadFormId) {
                    var el = $('.js-download-content.is-selected');

                    if (el.length) {
                        var filePath = el.data('file');
                        var id = el.data('id');
                        Mangrove._createCookie('wordpress_opticos_download', id);
                        setTimeout(function() {
                            $('<form></form>').attr('action', filePath).appendTo('body').submit().remove();
                        }, 1000);
                    }
                }
            });

            this.$doc.on(
                'click',
                '.js-load-prototypes',
                this._loadMorePrototypes.bind(this)
            );

            this.$doc.on(
                'click',
                '.js-load-videos',
                this._loadMoreVideos.bind(this)
            );

            this.$doc.on(
                'click',
                '.js-load-publications',
                this._loadMorePublications.bind(this)
            );

            this.$doc.on(
                'click',
                '.js-load-press',
                this._loadMorePress.bind(this)
            );

            this.$html.on(
                'click',
                '.js-share',
                this._openShareWindow
            );


        }, // _events()

        _detectFilters: function(e) {
            var filters = Mangrove.getUrlVars();

            if (filters.sector || filters.tag) {

                if (filters.sector) {
                    $('.js-prototype-filter').removeClass('is-active');
                    $('a[data-filter*=' + filters.sector + ']').addClass('is-active');
                }

                if (filters.tag) {
                    $('.js-prototype-filter-dropdown option[value*=' + filters.tag + ']').attr('selected', 'selected');
                }
            }
        },

        _openShareWindow: function(e) {
            var link = $(e.currentTarget).attr('href');
            if (link.substr(0, 6) != 'mailto') {
                e.preventDefault();
                window.open(link, '', 'width=600, height=400');
            }
        },

        getUrlVars: function() {
            var vars = {};
            var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
                vars[key] = value;
            });
            return vars;
        },

        _showGdpr: function() {
          if (localStorage.getItem("cookies-accepted") === null) {
            $('.js-gdpr').removeClass('hidden');
          }
        },

        _loadMorePrototypes: function() {

            Mangrove.$currentPrototypePage++;
            $('.js-prototypes-all').append(paginate(Mangrove.$tempPrototypes, Mangrove.$prototypesPerPage, Mangrove.$currentPrototypePage));

            if (Mangrove.$currentPrototypePage >= Mangrove.$maxPrototypePage) {
              $('.js-nav-prototypes').hide();
            } else {
              $('.js-nav-prototypes').show();
            }

            console.log('Selected: ' + Mangrove.$tempPrototypes.length);
            console.log('Max: ' + Mangrove.$maxPrototypePage);
            console.log('Current: ' + Mangrove.$currentPrototypePage);

            function paginate(array, page_size, page_number) {
                --page_number;
                return array.slice(page_number * page_size, (page_number + 1) * page_size);
            }

        },

        _loadAllPrototypes: function() {

            $.ajax({
                url: my_ajax_object.ajax_url,
                type: 'POST',
                data: {
                    action: 'load_prototypes'
                  },
                beforeSend: function() {
                    $('.js-load-prototypes').hide();
                    $('.blog-item__loading').show();
                },
                success: function(data) {
                    $('.js-load-prototypes').show();
                    $('.blog-item__loading').hide();
                    Mangrove.$allPrototypes = $(data);
                    Mangrove.$maxPrototypePage = Math.ceil($(data).length / Mangrove.$prototypesPerPage);
                    Mangrove._filterPrototypes();
                },
                error: function() {
                  console.log('Error');
                }
            });
        },

        _filterPrototypes: function(e) {

            if (Mangrove.$allPrototypes == false) {
                Mangrove._loadAllPrototypes();
            }

            if (e) {
                e.preventDefault();
                var el = $(e.currentTarget);

                if (e.currentTarget.classList.contains('js-prototype-filter')) {
                    $('.js-prototype-filter').removeClass('is-active');
                    el.toggleClass('is-active');
                    $('.js-prototype-filter-dropdown').find('option:eq(0)').prop('selected', true);
                }
                if (e.currentTarget.classList.contains('js-prototype-filter-dropdown')) {
                    $('.js-prototype-filter').removeClass('is-active');
                    $('.js-prototype-filter:eq(0)').toggleClass('is-active');
                }
            }

            var filters = [];
            var selected = [];

            Mangrove.$tempPrototypes = Mangrove.$allPrototypes;
            $('.js-empty-info').addClass('is-hidden');
            $('.js-prototype-item').remove();
            $('.js-nav-prototypes').show();

            $('.js-prototype-filter').filter('.is-active').each(function() {
                if (this.dataset.filter.length > 0) {
                    filters.push(this.dataset.filter);  
                }
            });

            $('.js-prototype-filter-dropdown option').filter(':selected').each(function() {
                if (this.value.length > 0) {
                    filters.push(this.value);
                }
            });

            if (filters.length > 0) {
                var filterClasses = filters.join('');

                Mangrove.$tempPrototypes.filter(filterClasses).each(function() {
                    selected.push(this);
                });

                if (selected.length == 0) {
                    $('.js-empty-info').removeClass('is-hidden');
                    $('.js-nav-prototypes').hide();
                } else {
                    Mangrove.$tempPrototypes = selected;
                    Mangrove.$currentPrototypePage = 1;
                    Mangrove.$maxPrototypePage = Math.ceil(Mangrove.$tempPrototypes.length / Mangrove.$prototypesPerPage);
                    $('.js-prototypes-all').append(Mangrove.$tempPrototypes.slice(0, 6));
                    $('.js-nav-prototypes').show();
                    console.log('Selected: ' + Mangrove.$tempPrototypes.length);
                    console.log('Max: ' + Mangrove.$maxPrototypePage);
                    console.log('Current: ' + Mangrove.$currentPrototypePage);

                    if (Mangrove.$currentPrototypePage >= Mangrove.$maxPrototypePage) {
                        $('.js-nav-prototypes').hide();
                    }

                }

            } else {
                Mangrove.$currentPrototypePage = 1;
                Mangrove.$maxPrototypePage = Math.ceil(Mangrove.$tempPrototypes.length / Mangrove.$prototypesPerPage);
                $('.js-prototypes-all').append(Mangrove.$tempPrototypes.slice(0, 6));
                $('.js-empty-info').addClass('is-hidden');
            }

        },

        _showSearch(event) {
            event.preventDefault();
            this.$header.toggleClass('has-active-search');
            $('.header .hero__content').toggleClass('has-active-search');
            this.$body.toggleClass('has-active-search');
        },

        _loadMoreVideos: function() {

            Mangrove.$currentVideosPage++;
            $('.js-videos-all').append(paginate(Mangrove.$tempVideos, Mangrove.$videosPerPage, Mangrove.$currentVideosPage));

            if (Mangrove.$currentVideosPage >= Mangrove.$maxVideosPage) {
              $('.js-nav-videos').hide();
            } else {
              $('.js-nav-videos').show();
            }

            console.log('Selected: ' + Mangrove.$tempVideos.length);
            console.log('Max: ' + Mangrove.$maxVideosPage);
            console.log('Current: ' + Mangrove.$currentVideosPage);

            Mangrove._initPopups();

            function paginate(array, page_size, page_number) {
                --page_number;
                return array.slice((page_number * page_size) - 6, ((page_number + 1) * page_size) - 6);
            }

        },

        _loadAllVideos: function() {

            $.ajax({
                url: my_ajax_object.ajax_url,
                type: 'POST',
                data: {
                    action: 'load_videos'
                },
                beforeSend: function() {
                    $('.js-load-videos').hide();
                    $('.blog-item__loading').show();
                },
                success: function(data) {
                    $('.js-load-videos').show();
                    $('.blog-item__loading').hide();
                    Mangrove.$allVideos = $(data);
                    console.log(Mangrove.$allVideos);
                    Mangrove.$maxVideosPage = Math.ceil(($(data).length + 6) / Mangrove.$videosPerPage);
                    Mangrove._filterVideos();
                    Mangrove._initPopups();
                },
                error: function() {
                    console.log('Error');
                }
            });

        },

        _filterVideos: function(e) {

            Mangrove.$tempVideos = Mangrove.$allVideos;
            $('.js-videos-empty-info').addClass('is-hidden');
            $('.js-video-item').remove();
            $('.js-nav-videos').show();


            Mangrove.$currentVideosPage = 1;
            Mangrove.$maxVideosPage = Math.ceil((Mangrove.$tempVideos.length + 6) / Mangrove.$videosPerPage);
            $('.js-videos-all').append(Mangrove.$tempVideos.slice(0, 3));
            $('.js-nav-videos').show();
            console.log('Selected: ' + Mangrove.$tempVideos.length);
            console.log('Max: ' + Mangrove.$maxVideosPage);
            console.log('Current: ' + Mangrove.$currentVideosPage);

            if (Mangrove.$currentVideosPage >= Mangrove.$maxVideosPage) {
                $('.js-nav-videos').hide();
            }
        },

        _loadMorePublications: function() {

            Mangrove.$currentPublicationsPage++;
            $('.js-publications-all').append(paginate(Mangrove.$tempPublications, Mangrove.$publicationsPerPage, Mangrove.$currentPublicationsPage));

            if (Mangrove.$currentPublicationsPage >= Mangrove.$maxPublicationsPage) {
              $('.js-nav-publications').hide();
            } else {
              $('.js-nav-publications').show();
            }

            console.log('Selected: ' + Mangrove.$tempPublications.length);
            console.log('Max: ' + Mangrove.$maxPublicationsPage);
            console.log('Current: ' + Mangrove.$currentPublicationsPage);

            Mangrove._initPopups();

            function paginate(array, page_size, page_number) {
                --page_number;
                return array.slice((page_number * page_size) - 6, ((page_number + 1) * page_size) - 6);
            }

        },

        _loadAllPublications: function() {

            $.ajax({
                url: my_ajax_object.ajax_url,
                type: 'POST',
                data: {
                    action: 'load_publications'
                },
                beforeSend: function() {
                    $('.js-load-publications').hide();
                    $('.blog-item__loading').show();
                },
                success: function(data) {
                    $('.js-load-publications').show();
                    $('.blog-item__loading').hide();
                    Mangrove.$allPublications = $(data);
                    Mangrove.$maxPublicationsPage = Math.ceil(($(data).length + 6) / Mangrove.$publicationsPerPage);
                    Mangrove._filterPublications();
                    Mangrove._initPopups();
                },
                error: function() {
                    console.log('Error');
                }
            });

        },

        _filterPublications: function(e) {

            Mangrove.$tempPublications = Mangrove.$allPublications;
            $('.js-publications-empty-info').addClass('is-hidden');
            $('.js-publication-item').remove();
            $('.js-nav-publications').show();


            Mangrove.$currentPublicationsPage = 1;
            Mangrove.$maxPublicationsPage = Math.ceil((Mangrove.$tempPublications.length + 6) / Mangrove.$publicationsPerPage);
            $('.js-publications-all').append(Mangrove.$tempPublications.slice(0, 3));
            $('.js-nav-publications').show();
            console.log('Selected: ' + Mangrove.$tempPublications.length);
            console.log('Max: ' + Mangrove.$maxPublicationsPage);
            console.log('Current: ' + Mangrove.$currentPublicationsPage);

            if (Mangrove.$currentPublicationsPage >= Mangrove.$maxPublicationsPage) {
                $('.js-nav-publications').hide();
            }
        },

        _loadMorePress: function() {

            Mangrove.$currentPressPage++;
            $('.js-press-all').append(paginate(Mangrove.$tempPress, Mangrove.$pressPerPage, Mangrove.$currentPressPage));

            if (Mangrove.$currentPressPage >= Mangrove.$maxPressPage) {
              $('.js-nav-press').hide();
            } else {
              $('.js-nav-press').show();
            }

            console.log('Selected: ' + Mangrove.$tempPress.length);
            console.log('Max: ' + Mangrove.$maxPressPage);
            console.log('Current: ' + Mangrove.$currentPressPage);

            function paginate(array, page_size, page_number) {
                --page_number;
                return array.slice((page_number * page_size) - 6, ((page_number + 1) * page_size) - 6);
            }

        },

        _loadAllPress: function() {

            $.ajax({
                url: my_ajax_object.ajax_url,
                type: 'POST',
                data: {
                    action: 'load_press'
                },
                beforeSend: function() {
                    $('.js-load-press').hide();
                    $('.blog-item__loading').show();
                },
                success: function(data) {
                    $('.js-load-press').show();
                    $('.blog-item__loading').hide();
                    Mangrove.$allPress = $(data);
                    Mangrove.$maxPressPage = Math.ceil(($(data).length + 6) / Mangrove.$pressPerPage);
                    Mangrove._filterPress();
                },
                error: function() {
                    console.log('Error');
                }
            });

        },

        _filterPress: function(e) {

            Mangrove.$tempPress = Mangrove.$allPress;
            $('.js-press-empty-info').addClass('is-hidden');
            $('.js-press-item').remove();
            $('.js-nav-press').show();


            Mangrove.$currentPressPage = 1;
            Mangrove.$maxPressPage = Math.ceil((Mangrove.$tempPress.length + 6) / Mangrove.$pressPerPage);
            $('.js-press-all').append(Mangrove.$tempPress.slice(0, 3));
            $('.js-nav-press').show();
            console.log('Selected: ' + Mangrove.$tempPress.length);
            console.log('Max: ' + Mangrove.$maxPressPage);
            console.log('Current: ' + Mangrove.$currentPressPage);

            if (Mangrove.$currentPressPage >= Mangrove.$maxPressPage) {
                $('.js-nav-press').hide();
            }
        },

        _initAnimiation: function() {
            var hills = $('.js-animation-hills');
            var train = $('.js-animation-train');
            var train_station = $('.js-animation-train-station');
            var city_scape_1 = $('.js-animation-cityscape-1');
            var city_scape_2 = $('.js-animation-cityscape-2');
            var city_scape_3 = $('.js-animation-cityscape-3');
            var waterfront = $('.js-animation-waterfront');
            var bus = $('.js-animation-bus');
            var bicycles = $('.js-animation-bicycles');
            var waves = $('.js-animation-waves');
            var boats = $('.js-animation-boats');

            var tl = new TimelineMax({
                repeat: 2,
                repeatDelay: 2,
                yoyo: true,
                delay: 2,
                onComplete: function() {
                    $('.js-animation-play').addClass('is-active');
                },
                onStart: function() {
                    $('.js-animation-play').removeClass('is-active');
                }
            });

            tl.to(hills, 2, {
                    y: '-2%',
                    ease: Power1.easeInOut
                }, 'hillsGo')
                .to(train, 2, {
                    y: '3%',
                    ease: Power1.easeInOut
                }, 'hillsGo')
                .to(train_station, 2, {
                    y: '3%',
                    ease: Power1.easeInOut
                }, 'hillsGo')
                .to(city_scape_1, 2, {
                    y: '8%',
                    ease: Power1.easeInOut
                }, 'hillsGo')
                .to(city_scape_2, 2, {
                    y: '11%',
                    ease: Power1.easeInOut
                }, 'hillsGo')
                .to(city_scape_3, 2, {
                    y: '13%',
                    ease: Power1.easeInOut
                }, 'hillsGo')
                .to(bicycles, 2, {
                    y: '14.5%',
                    ease: Power1.easeInOut
                }, 'hillsGo')
                .to(bus, 2, {
                    y: '13.8%',
                    ease: Power1.easeInOut
                }, 'hillsGo')
                .to(waterfront, 2, {
                    y: '15%',
                    ease: Power1.easeInOut
                }, 'hillsGo')
                .to(waves, 2, {
                    y: '15%',
                    ease: Power1.easeInOut
                }, 'hillsGo')
                .to(boats, 2, {
                    y: '15%',
                    ease: Power1.easeInOut
                }, 'hillsGo')


            this.$html.on('click', '.js-animation-play', function() {
                tl.restart();
            })
        },

        _initScrollModals: function() {
            $(window).on('scroll', function() {
                if (($(window).scrollTop() >= ($(document).height() - $(window).height()) * 0.5) && Mangrove.$scrollFired == false) {
                    Mangrove.$scrollFired = true;

                    $.magnificPopup.open({
                        items: {
                            src: $('#download-content-scroll'),
                            type: 'inline'
                        },
                        midClick: true,
                        removalDelay: 100,
                        mainClass: 'popup--animate',
                        callbacks: {
                            beforeOpen: function() {
                                $('.js-download-content').removeClass('is-selected');
                                var el = $('meta.js-download-content');
                                var title = el.data('title');
                                el.addClass('is-selected');

                                $.ajax({
                                    url: my_ajax_object.ajax_url,
                                    type: 'GET',
                                    data: {
                                        action: 'load_download_form',
                                        download_title: title,
                                        form_id: Mangrove.$downloadFormId,
                                        show_title: 'no'
                                    },
                                    success: function(data) {
                                        $('#download-content-form-scroll').html(data);
                                    },
                                    error: function() {
                                        console.log('Error');
                                    }
                                });

                            },

                        }

                    });
                }
            });
        },

        _createCookie: function(name, value, days) {
            var expires = '';
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + value + expires + "; path=/";
        },

        _filterSector: function(e) {
            e.preventDefault();
            var el = $(e.currentTarget);
            var category = el.data('category');
            this.$btnSector.removeClass('is-active');
            el.addClass('is-active');
            this.$gridSector.removeClass('is-active').addClass('is-hidden');
            $('.js-grid-' + category).removeClass('is-hidden').addClass('is-active');
        },

        _cookieAccept: function(e) {
          e.preventDefault();
          $('.js-gdpr').addClass('hidden');
          localStorage.setItem('cookies-accepted', 'yes');
        },

        _scrollToSection: function(e) {
            e.preventDefault();

            var section = $(e.currentTarget).attr('href');

            var el = $(section);
            if (el.length) {
                this.$bodyHtml.animate({
                    scrollTop: el.offset().top
                }, 200);
            }
        },

        _scrollHero: function(e) {
            e.preventDefault();

            var section = $('.primary-block--mmh-home');

            var el = $(section);
            if (el.length) {
                this.$bodyHtml.animate({
                    scrollTop: el.offset().top
                }, 200);
            }
        },

        _initInfiniteScroll: function() {
            this.$posts.jscroll({
                loadingHtml: '<img class="blog-item__loading" src="/wp-content/themes/_mgstarter/img/icon-loading.svg">',
                padding: 0,
                autoTrigger: false,
                nextSelector: '.js-pagination a',
                contentSelector: '.js-posts'
            });
        },


        _initPopups: function() {

            $('.js-popup, .js-popup-contact a').magnificPopup({
                type: 'inline',
                midClick: true,
                removalDelay: 100,
                mainClass: 'popup--animate'
            });

            $(document).ready(function () {
                setTimeout(function() {
                    if($('#download-content').length /* && Cookies.get('gform_wrapper') !== 'close'*/ ) {
                        $('.js-download-content').magnificPopup({
                            type: 'inline',
                            midClick: true,
                            removalDelay: 100,
                            mainClass: 'popup--animate',
                            callbacks: {
                                open: function() {
                                    $('.js-download-content').removeClass('is-selected');
                                    var el = $(this.currItem.el);
                                    var title = el.data('title');
                                    el.addClass('is-selected');

                                    //$.ajax({
                                    //    url: my_ajax_object.ajax_url,
                                    //    type: 'GET',
                                    //    data: {
                                    //        action: 'load_download_form',
                                    //        download_title: title,
                                    //        form_id: Mangrove.$downloadFormId
                                    //    },
                                    //    success: function(data) {
                                    //        $('#download-content-form').html(data);
                                    //    },
                                    //    error: function() {
                                    //        console.log('Error');
                                    //    }
                                    //});

                                },

                            }
                        });
                    }
                }, 1000);
            });

            $('.js-popup-image').magnificPopup({
                type: 'image',
                midClick: true,
                removalDelay: 100,
                mainClass: 'popup--animate',
                image: {
                    markup: '<div class="popup--media">' +
                        '<div class="mfp-close"></div>'+
                        '<div class="mfp-img"></div>' +
                        '<div class="mfp-bottom-bar">' +
                        '</div>',

                    cursor: 'mfp-zoom-out-cur', // Class that adds zoom cursor, will be added to body. Set to null to disable zoom out cursor.

                    titleSrc: 'title',

                    verticalFit: true, // Fits image in area vertically

                    tError: '<a href="%url%">The image</a> could not be loaded.' // Error message
                }
            });

            $('.js-popup-video').magnificPopup({
                type: 'iframe',
                midClick: true,
                removalDelay: 100,
                mainClass: 'popup--animate',
                iframe: {
                    markup: '<div class="mfp-iframe-scaler">' +
                        //'<div class="mfp-close"></div>'+
                        '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                        '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button

                    cursor: 'mfp-zoom-out-cur', // Class that adds zoom cursor, will be added to body. Set to null to disable zoom out cursor.
                    tError: '<a href="%url%">The image</a> could not be loaded.', // Error message
                    patterns: {
                        youtube_short: {
                          index: 'youtu.be/',
                          id: 'youtu.be/',
                          src: '//www.youtube.com/embed/%id%?autoplay=1'
                        }
                    }
                }
            });

            $('.popup-gallery').magnificPopup({
              delegate: 'a',
              type: 'image',
              tLoading: 'Loading image #%curr%...',
              mainClass: 'mfp-img-documented',
              closeBtnInside: true,
              gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
              },
              image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
              },
              callbacks: {
                buildControls: function() {
                  this.contentContainer.append(this.arrowLeft.add(this.arrowRight));
                }
              }
            });

        },

        _initSliders: function() {
            $('.js-slider-testimonial').slick({
                dots: true,
                arrows: false,
                adaptiveHeight: false
            });
        },

        _showMobileNav: function(e) {
            e.preventDefault();
            this.$mobileNav.toggleClass('is-active');
            this.$body.toggleClass('u-prevent-scroll');
            this.$hamburger.toggleClass('is-active');
        },
        _closeBanner: function() {
            $(document).ready(function () {
              $('#hero__banner').on('click', function(){
                  this.style.display = 'none';
              });
            });
        },
        _videoMarquee: function() {
          
            const root = document.documentElement;
            const marqueeElementsDisplayed = getComputedStyle(root).getPropertyValue("--marquee-elements-displayed");
            const marqueeContent = document.querySelector("ul.videos__marquee__content");

            root.style.setProperty("--marquee-elements", marqueeContent.children.length);

            for(let i=0; i<marqueeElementsDisplayed; i++) {
              marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true));
            }
        }

    }; // Mangrove

    Mangrove.init();

    $('ul.subnav-tabs').each(function(){

      // For each set of tabs, we want to keep track of
      // which tab is active and its associated content
      var $active, $content, $links = $(this).find('a');

      // If the location.hash matches one of the links, use that as the active tab.
      // If no match is found, use the first link as the initial active tab.
      $active = $($links.filter('[href="'+location.hash+'"]')[0] || $links[0]);
      $active.addClass('active');

      $content = $($active[0].hash);

      // Hide the remaining content
      $links.not($active).each(function () {
        $(this.hash).hide();
      });

      // Bind the click event handler
      $(this).on('click', 'a', function(e){
        // Make the old tab inactive.
        window.location.hash = this.hash;

        // Prevent the anchor's default click action
        e.preventDefault();

        $active.removeClass('active');
        $content.hide();

        // Update the variables with the new link and content
        $active = $(this);
        $content = $(this.hash);

        // Make the tab active.
        $active.addClass('active');
        $content.show();
        $(window).trigger('resize');


      });
    });
    
    $('.ui-accordion-header').on('click keypress', function(e){
      e.preventDefault();
      $(this).toggleClass('expanded');
      $(this).parent().find('.ui-accordion-content').slideToggle(100,'swing');
      
      
    });

})(jQuery, window, document);
